.rc-select {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    color: #666;
    line-height: 28px;
    cursor: pointer;
}

.rc-select-allow-clear .rc-select-selection--single .rc-select-selection__rendered {
    padding-right: 40px;
}

.rc-select ul,
.rc-select li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.rc-select-selection__clear {
    user-select: none;
    font-size: 18px;
    background: #999999d6;
    height: 23px;
    width: 23px;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    top: 9px !important;
    right: 3px !important;
    z-index: 9;
    color: white;
    font-weight: bold;
    position: absolute;
    line-height: 25px;
    display: none;
}

.rc-select-allow-clear:hover .rc-select-selection__clear {
    display: block;
}

.rc-select > ul > li > a {
    padding: 0;
    background-color: #fff;
}

.rc-select-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
}

.rc-select-item:hover, .rc-select-item-option-active {
    background-color: #f1f1f1;
}

.rc-select-selector {
    display: flex;
    justify-content: flex-start;
    /*border: 1px solid #CED4DA;*/
    border: 1px solid #ced4da;
    font-weight: 400;
    font-size: 0.875rem;
    border-radius: 4px;
    display: block;
    width: 100%;
    height: 2.35rem;
    /*padding: 0.65rem 0.75rem;*/
    padding: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    /*border: 1px solid #e2e5e8;*/
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: left;
    cursor: pointer;
}

.rc-select-selection-search {
    /*display: none;*/
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.rc-select-selection-search-input {
    cursor: pointer;
}

.rc-select-multiple .rc-select-selection-search-input {
    padding: 7px !important;
}

.rc-select-selection-search-input:focus-visible {
    outline: none;
}

.rc-select-selector {
    line-height: 1.5;
    /*height: 46px;*/
    height: calc(3.5rem + 2px);
    padding-top: 1.125rem;
    /*padding-top: 0.525rem;*/
    padding-bottom: 0.625rem;
}

.rc-select-multiple .rc-select-selector {
    /*padding-top: 0.425rem;*/
    padding-top: 1rem;
    padding-bottom: 0.625rem;
}

.rc-select-single .rc-select-selection-item {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: calc(3.5rem + 2px);

    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
    padding-left: 1.2rem;
    color: black;
    font-weight: 500;
}


.rc-select-selection-placeholder {
    position: absolute;
    left: 5px;
    color: black;
    font-weight: 500;
}

.rc-select-selection-search-input {
    display: block;
    /*width: 100%;*/
    /*padding: 0.47rem 0.75rem;*/
    /*padding: 0.7rem 10px;*/
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-input-bg);
    background-clip: padding-box;
    border: 1px solid var(--bs-input-border-color);
    /*-webkit-appearance: none;*/
    /*-moz-appearance: none;*/
    /*appearance: none;*/
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    /*transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;*/
    /*opacity: 1 !important;*/

    height: calc(3.5rem + 2px);

    padding-top: 1.725rem;
    /*padding-top: 0.725rem;*/
    padding-bottom: 0.625rem;
    padding-left: 1rem;
    color: black;
    font-weight: 500;
}

.rc-select-arrow-icon:after {
    display: block;
    content: ' ';
    background-image: url(./arrow-icon.svg);
    background-size: 34px 34px;
    height: 34px;
    width: 34px;
    transition: transform .3s, -webkit-transform .3s;
}

.rc-select-open .rc-select-arrow-icon:after {
    transform: rotate(180deg);
    transition: transform .3s, -webkit-transform .3s;
}

.rc-select-arrow {
    height: 39px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 40px;
    outline: none;
    display: none;
}

.rc-select-show-search:hover .rc-select-clear {
    display: block;
}

.rc-select-clear {
    user-select: none;
    height: 25px;
    position: absolute;
    top: 6px;
    right: 8px;
    width: 25px;
    outline: none;
    text-align: center;
    background: #495057;
    border-radius: 50%;
    line-height: 1.2;
    display: none;
    cursor: pointer;
}


.rc-select-clear-icon {
    font-size: 22px;
    color: white;
}

.rc-select-arrow b {
    border-color: #999999 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    width: 0;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    left: 50%;
}

.rc-select-selection {
    outline: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
}

.rc-select-disabled > .rc-select-selection {
    /*background: #e9ecef;*/
    color: #555 !important;
    background-color: rgba(239, 239, 239, 0.3);
}

.rc-select-disabled .rc-select-selection-item {
    background-color: rgb(59 59 59 / 8%);
    cursor: not-allowed;
}

.rc-select-selection__placeholder {
    position: absolute;
    top: 5px;
    color: #aaa;
    display: block;
}

.rc-select-selection__clear:after {
    content: '\D7';
}

.rc-select-focused .rc-select-selection {
    border-color: #23c0fa;
    box-shadow: 0 0 2px rgba(45, 183, 245, 0.8);
}

.rc-select-enabled .rc-select-selection:hover {
    border-color: #23c0fa;
    box-shadow: 0 0 2px rgba(45, 183, 245, 0.8);
}

.rc-select-enabled .rc-select-selection:active {
    border-color: #2db7f5;
}

.rc-select-selection--single {
    height: 44px;
    line-height: 28px;
    cursor: pointer;
    position: relative;
}

.rc-select-selection--single .rc-select-selection-selected-value {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 5px;
}

.rc-select-selection--single .rc-select-selection__rendered {
    height: 44px;
    position: relative;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 10px;
    padding-right: 20px;
    line-height: 28px;
}

.rc-select-selection--single .rc-select-selection__clear {
    top: 0;
    right: 20px;
}

.rc-select-disabled {
    color: #ccc;
    cursor: not-allowed;
}

.rc-select-disabled .rc-select-selection--single,
.rc-select-disabled .rc-select-selection__choice__remove {
    cursor: not-allowed;
    color: #ccc;
}

.rc-select-disabled .rc-select-selection--single:hover,
.rc-select-disabled .rc-select-selection__choice__remove:hover {
    cursor: not-allowed;
    color: #ccc;
}

.rc-select-search__field__wrap {
    display: inline-block;
}

.rc-select-search__field__placeholder {
    position: absolute;
    top: 0;
    left: 3px;
    color: #aaa;
}

.rc-select-search--inline {
    width: 100%;
}

.rc-select-search--inline .rc-select-search__field__wrap {
    width: 100%;
}

.rc-select-search--inline .rc-select-search__field {
    border: none;
    font-size: 100%;
    background: transparent;
    outline: 0;
    width: 100%;
}

.rc-select-search--inline .rc-select-search__field::-ms-clear {
    display: none;
}

.rc-select-search--inline .rc-select-search__field__mirror {
    position: absolute;
    top: -999px;
    left: 0;
    white-space: pre;
}

.rc-select-search--inline > i {
    float: right;
}

.rc-select-enabled.rc-select-selection--multiple {
    cursor: text;
}

.rc-select-selection--multiple {
    min-height: 44px;
}

.rc-select-selection--multiple .rc-select-search--inline {
    float: left;
    width: auto;
}

.rc-select-selection--multiple .rc-select-search--inline .rc-select-search__field {
    width: 0.75em;
    height: 42px;
}

.rc-select-selection--multiple .rc-select-search--inline .rc-select-search__field__wrap {
    width: auto;
}

.rc-select-selection--multiple .rc-select-search__field__placeholder {
    top: 5px;
    left: 8px;
}

.rc-select-selection--multiple .rc-select-selection__rendered {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
    padding-bottom: 2px;
}

.rc-select-selection--multiple .rc-select-selection__rendered .rc-select-selection__choice {
    margin-top: 4px;
    /*line-height: 20px;*/
    line-height: 34px;
}

.rc-select-selection--multiple .rc-select-selection__clear {
    top: 1px;
    right: 8px;
}

.rc-select-enabled .rc-select-selection__choice {
    cursor: default;
}

.rc-select-enabled .rc-select-selection__choice:hover .rc-select-selection__choice__remove {
    opacity: 1;
    transform: scale(1);
}

.rc-select-enabled .rc-select-selection__choice:hover .rc-select-selection__choice__content {
    margin-left: -8px;
    margin-right: 8px;
}

.rc-select-enabled .rc-select-selection__choice__disabled {
    cursor: not-allowed;
}

.rc-select-enabled .rc-select-selection__choice__disabled:hover .rc-select-selection__choice__content {
    margin-left: 0;
    margin-right: 0;
}

.rc-select .rc-select-selection__choice {
    background-color: #f3f3f3;
    border-radius: 4px;
    float: left;
    padding: 0 15px;
    margin-right: 4px;
    position: relative;
    overflow: hidden;
    transition: padding 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045), width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.rc-select .rc-select-selection__choice__content {
    margin-left: 0;
    margin-right: 0;
    transition: margin 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.rc-select .rc-select-selection__choice-zoom-enter,
.rc-select .rc-select-selection__choice-zoom-appear,
.rc-select .rc-select-selection__choice-zoom-leave {
    animation-duration: .3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 0;
    animation-play-state: paused;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.rc-select .rc-select-selection__choice-zoom-leave {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.rc-select .rc-select-selection__choice-zoom-enter.rc-select-selection__choice-zoom-enter-active,
.rc-select .rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
    animation-play-state: running;
    animation-name: rcSelectChoiceZoomIn;
}

.rc-select .rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
    animation-play-state: running;
    animation-name: rcSelectChoiceZoomOut;
}

@keyframes rcSelectChoiceZoomIn {
    0% {
        transform: scale(0.6);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes rcSelectChoiceZoomOut {
    to {
        transform: scale(0);
        opacity: 0;
    }
}

.rc-select .rc-select-selection__choice__remove {
    color: #919191;
    cursor: pointer;
    font-weight: bold;
    padding: 0 0 0 8px;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    top: 0;
    right: 2px;
    transition: opacity .3s, transform .3s;
}

.rc-select .rc-select-selection__choice__remove:before {
    content: '\D7';
}

.rc-select .rc-select-selection__choice__remove:hover {
    color: #333;
}

.rc-select-dropdown {
    background-color: white;
    border: 1px solid #d9d9d9;
    box-shadow: 0 0px 4px #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    left: -9999px;
    top: -9999px;
    position: absolute;
    outline: none;
    z-index: 9999;
}

.rc-select-dropdown:empty,
.rc-select-dropdown-hidden {
    display: none;
}

.rc-select-dropdown-menu {
    outline: none;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 9999;
}

.rc-select-dropdown-menu > li {
    margin: 0;
    padding: 0;
}

.rc-select-dropdown-menu-item-group-list {
    margin: 0;
    padding: 0;
}

.rc-select-dropdown-menu-item-group-list > li.rc-select-menu-item {
    padding-left: 20px;
}

.rc-select-dropdown-menu-item-group-title {
    color: #999;
    line-height: 1.5;
    padding: 8px 10px;
    border-bottom: 1px solid #dedede;
}

li.rc-select-dropdown-menu-item {
    margin: 0;
    position: relative;
    display: block;
    padding: 7px 10px;
    font-weight: normal;
    color: #666;
    white-space: nowrap;
}

li.rc-select-dropdown-menu-item-disabled {
    color: #ccc;
    cursor: not-allowed;
}

li.rc-select-dropdown-menu-item-selected {
    color: #666;
    background-color: #ddd;
}

li.rc-select-dropdown-menu-item-active {
    background-color: #5897fb;
    color: white;
    cursor: pointer;
}

li.rc-select-dropdown-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    background-color: #e5e5e5;
    line-height: 0;
}

.rc-select-dropdown-slide-up-enter,
.rc-select-dropdown-slide-up-appear {
    animation-duration: .3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
}

.rc-select-dropdown-slide-up-leave {
    animation-duration: .3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
}

.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpIn;
    animation-play-state: running;
}

.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpOut;
    animation-play-state: running;
}

.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownIn;
    animation-play-state: running;
}

.rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownOut;
    animation-play-state: running;
}

.rc-select-selection-item {
    /*   opacity: 1;
       display: inline-block;
       padding: 5px 10px;
       background: #f2f2f2;
       margin-right: 5px;*/
    position: relative;
    float: left;
    max-width: 100%;
    margin-right: 4px;
    padding: 5px 10px 5px 15px;
    overflow: hidden;
    color: rgba(0, 0, 0, .65);
    background-color: #fafafa;
    /*border: 1px solid #e8e8e8;*/
    border: 1px solid #ced4da;
    border-radius: 5px;
    cursor: default;
    -webkit-transition: padding .3s cubic-bezier(.645, .045, .355, 1);
}

.rc-select-multiple .rc-select-selection-item {
    margin-top: 6px;
}

.rc-select-selection-search-mirror {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    opacity: 0;
    pointer-events: none;
    display: none;
}

.rc-select-selection-overflow-item-suffix {
    position: static;
    float: left;
    width: auto;
    max-width: 100%;
    padding: 0;
}

.rc-select-multiple .rc-select-selection-search {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
}

.rc-select-multiple .rc-select-selection-search input {
    height: 100%;
    font-size: 100%;
    line-height: 1;
    background: 0 0;
    /*border-width: 0;*/
    border-radius: 4px;
    outline: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    border: none;
    outline: none;
}

.rc-select-single .rc-select-open .rc-select-selection-item {
    opacity: 0.3;
}

.rc-select-single .rc-select-selection-search-input {
    width: 100%;
}

.rc-select-selection-placeholder {
    position: absolute;
    line-height: 40px;
    padding-left: 15px;
}

.rc-select-selection-overflow {
    margin-left: 10px;
}

.rc-select-selection-item-content {
    margin-right: 10px;
}


@keyframes rcSelectDropdownSlideUpIn {
    0% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
    }
}

@keyframes rcSelectDropdownSlideUpOut {
    0% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
    }
}

@keyframes rcSelectDropdownSlideDownIn {
    0% {
        opacity: 0;
        transform-origin: 0% 100%;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform-origin: 0% 100%;
        transform: scaleY(1);
    }
}

@keyframes rcSelectDropdownSlideDownOut {
    0% {
        opacity: 1;
        transform-origin: 0% 100%;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform-origin: 0% 100%;
        transform: scaleY(0);
    }
}

.rc-select-open .rc-select-arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.rc-select-item-empty {
    padding: 10px;
}

/*# sourceMappingURL=common.css.map*/
