.new-header{
    /* background-color: pink; */
    padding: 10px;
}
.card-header-image{
    display:flex !important;
    justify-content:space-between !important;
    background-color:  #366e91;
    height: 50px;
   
}

.text{
    font-size: 16px;
    padding-top: 10px;
    color: #f1f3f4;
}
.checkboxes-design{
    display: flex;
}
/* .new-class{
    display: flex;
    justify-content: space-around;
    align-items: center;
} */
.card-body{
    padding: 6px !important;
    /* display: flex;
    justify-content: space-around; */
   
}
.new-label{
    color: #f1f3f4;
}
/* .container {

   padding-left: 10px;     
  } */