.new-border{
    border: 1px solid #e2e5e894;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px 10px;
}
.new-icon{
    font-size: 18px;
    width: 100px;
    text-align: center;
    color: red;
    cursor: pointer;
    padding:5px 8px;

}
.div-icon{
    padding-top: 50px;
}
